import {
  BOOKINGS_FES_BASE_DOMAIN,
  BOOKINGS_WIDGET_BASE_DOMAIN,
  SINGLE_SERVICE_EDITOR_X_PRESET_ID,
  SINGLE_SERVICE_PRESET_ID,
} from '../consts';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { WidgetData } from '../../legacy/types';
import { isAnywhereFlow } from '../utils/anywhere/anywhere';
import { getResourceQueryParam } from '../utils/queryParams/queryParams';
import { getSiteRolloutStatusForV1Shutdown } from '@wix/ambassador-bookings-v1-site-rollout-status/http';
import settingsParams from '../components/BookOnline/settingsParams';
import { getPresetId } from '../components/BookOnline/controllerPrePageReady';
import { DisplayServicesByOptions } from '../types/types';
import {
  getInstance,
  getServerBaseUrl,
  getUrlQueryParamValue,
  BookingsQueryParams,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import { NotificationsServer } from '@wix/ambassador-notifications-server/http';
import { CatalogServiceDto } from '@wix/bookings-uou-types';
import { mapServiceTypeV1ToV2 } from '../utils/serviceDetails/serviceDetails';

export const CATALOG_SERVER_URL = '_api/services-catalog';
export const XSRF_HEADER_NAME = 'X-XSRF-TOKEN';
export const REVISION_HEADER_NAME = 'x-wix-site-revision';
export const NOTIFICATIONS_SERVER_URL = '_api/notifications-server';

const requestCacheKey = {
  getWidgetData: 'getWidgetData',
};

export class BookingsApi {
  private authorization: string;
  private readonly flowAPI: ControllerFlowAPI;
  private serverBaseUrl: string;
  private shouldWorkWithAppSettings: boolean;
  private networkCache: Map<string, any>;
  constructor({
    flowAPI,
    baseUrl,
    shouldWorkWithAppSettings = true,
    networkCache,
  }: {
    flowAPI: ControllerFlowAPI;
    baseUrl?: string;
    shouldWorkWithAppSettings?: boolean;
    networkCache: Map<string, any>;
  }) {
    this.authorization = getInstance({
      wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
      appParams: flowAPI.controllerConfig.appParams,
    });
    this.flowAPI = flowAPI;
    this.serverBaseUrl = getServerBaseUrl({
      wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
      appParams: flowAPI.controllerConfig.appParams,
    });
    this.networkCache = networkCache;
    this.shouldWorkWithAppSettings = shouldWorkWithAppSettings;
  }

  private async cachedRequest<T>(
    request: () => Promise<T>,
    key: string,
  ): Promise<T> {
    const useCache =
      this.flowAPI.experiments.enabled(
        'specs.bookings.useClientCacheInServiceList',
      ) && !this.flowAPI.environment.isSSR;

    if (useCache && this.networkCache.has(key)) {
      return this.networkCache.get(key);
    }
    const result = await request();

    if (useCache) {
      this.networkCache.set(key, result);
    }

    return result;
  }

  async notifyOwnerNonPremiumEnrollmentAttempt({
    service,
  }: {
    service: CatalogServiceDto;
  }) {
    const notificationService = NotificationsServer(
      `${this.serverBaseUrl}${NOTIFICATIONS_SERVER_URL}`,
    ).NotificationsSettings();
    return notificationService({
      authorization: this.authorization,
    }).missedBooking({ serviceType: mapServiceTypeV1ToV2(service.type) });
  }

  async notifyOwnerNonPricingPlanEnrollmentAttempt(data: object) {
    return this.flowAPI.httpClient.post(
      `${BOOKINGS_FES_BASE_DOMAIN}/pricing-plans/invalidSetup`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          [REVISION_HEADER_NAME]:
            this.flowAPI.controllerConfig.wixCodeApi.site.revision,
          [XSRF_HEADER_NAME]:
            this.flowAPI.controllerConfig.platformAPIs.getCsrfToken(),
        },
      },
    );
  }

  async getWidgetData(): Promise<WidgetData> {
    const {
      environment: { isEditor, isSSR, isViewer, isPreview, isEditorX },
      httpClient,
      controllerConfig: { config, wixCodeApi, platformAPIs },
      experiments,
      settings,
    } = this.flowAPI;

    const buildServicesFilter = () => {
      if (isEditor || this.shouldWorkWithAppSettings) {
        return {};
      }

      if (
        [SINGLE_SERVICE_PRESET_ID, SINGLE_SERVICE_EDITOR_X_PRESET_ID].includes(
          getPresetId(config, isEditorX),
        )
      ) {
        const selectedService = settings.get(settingsParams.selectedService);
        if (selectedService) {
          return {
            serviceId: selectedService,
          };
        } else {
          return {
            limit: 1,
          };
        }
      }
      const displayServicesBy = settings.get(settingsParams.displayServicesBy);

      if (displayServicesBy === DisplayServicesByOptions.BY_SERVICES) {
        const selectedCategories = settings.get(
          settingsParams.selectedCategories,
        );

        if (selectedCategories.length) {
          return { categoryIds: selectedCategories.join(',') };
        }
      }

      if (displayServicesBy === DisplayServicesByOptions.BY_SPECIFIC_SERVICES) {
        const selectedServices = settings.get(settingsParams.selectedServices);

        if (selectedServices.length) {
          return { serviceIds: selectedServices.join(',') };
        }
      }

      if (displayServicesBy === DisplayServicesByOptions.BY_LOCATIONS) {
        const selectedLocations = settings.get(
          settingsParams.selectedLocations,
        );
        if (selectedLocations.length) {
          return { locationIds: selectedLocations.join(',') };
        }
      }

      return {};
    };

    const servicesFilter = buildServicesFilter();

    const isAnywhereInd = await isAnywhereFlow(
      wixCodeApi,
      experiments,
      isPreview,
    );
    const publicDataOverrideURL = isAnywhereInd
      ? getUrlQueryParamValue(
          wixCodeApi,
          BookingsQueryParams.PUBLIC_DATA_OVERRIDES,
        )
      : undefined;
    const isLiveSiteNoSSR = isViewer && !isSSR;
    const metaSiteId = isLiveSiteNoSSR ? platformAPIs.bi?.metaSiteId : '';
    const serverUrl = `${this.serverBaseUrl}${BOOKINGS_WIDGET_BASE_DOMAIN}`;

    const resourceQueryParam = getResourceQueryParam(
      this.flowAPI.controllerConfig.wixCodeApi,
    );

    return this.cachedRequest(
      async () =>
        (
          await httpClient.get<WidgetData>(serverUrl, {
            params: {
              viewMode: isEditor ? 'editor' : 'site',
              cacheId: metaSiteId,
              externalId: config.externalId,
              ...resourceQueryParam,
              publicDataOverrides: publicDataOverrideURL,
              ...servicesFilter,
            },
          })
        ).data,
      `${requestCacheKey.getWidgetData}-${JSON.stringify(resourceQueryParam)}`,
    );
  }

  async isEcomSite(): Promise<boolean> {
    try {
      const { data: response } = await this.flowAPI.httpClient.request(
        getSiteRolloutStatusForV1Shutdown({}),
      );
      return Boolean(response.siteRolloutStatus?.isBookingPlatformReady);
    } catch (e) {
      return true;
    }
  }
}
